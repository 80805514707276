import '../scss/style.scss'
import $ from "jquery";
import gsap from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
// import Swiper JS
import Swiper from 'swiper/bundle';

// import imagesLoaded from 'imagesloaded';
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);
import Splitting from 'splitting';
import "regenerator-runtime/runtime";

function isMobile() {
	var isMobile = false //initiate as false
	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
		|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
		isMobile = true
	}
	return isMobile
}

gsap.registerPlugin(ScrollTrigger);

let hasScrollSmooth = true,
    flagPD = false;
let locoScroll;
let scrollMain = $('.scrollmain');
let header = $('header');
let links = $('.menu li .link');
//let startIntro = $('#startIntro');
let soundIcon = $('#soundIcon');
let introVid = document.getElementById("introVid");
let navToggle = $('#navToggle');
let nav = $('nav');

let signupToggle = $('#signupToggle');
let signupToggleMobile = $('#signupToggleMobile');
let signupToggleAbout = $('#signupToggleAbout');
let popupSignup = $('#popupSignup');
let closeSignup = $('#closeSignup');
let signupSubmit = $('#signupSubmit');
let popupSuccess = $('#popupSuccess');
let closeSuccess = $('#closeSuccess');
let successClose = $('#successClose');
let popupSignupInner = $('#popupSignupInner');
let popupSuccessInner = $('#popupSuccessInner');
let featureBtn = $('#feaCta');
let featureBtnAlias = document.querySelector('#feaCta');
let moreInfo = $('#moreInfo');
let popupMoreInfo = $('#popupMoreInfo');
let closeMoreInfo = $('#closeMoreInfo');

let aboutItem1 = $('.about_item.--first');
let aboutItem2 = $('.about_item.--second');
let aboutItem3 = $('.about_item.--third');
let aboutItem4 = $('.about_item.--fourth');
let popupAll = $('.popup-info');
let popup1 = $('#about-1');
let popup2 = $('#about-2');
let popup3 = $('#about-3');
let popup4 = $('#about-4');
let popupClose = $('.popup-info .ic-close');
const optAniText = {
    stagger: 0.02,
    staggerContent: 0.008,
    duration: 0.7,
    durationContent: 0.4,
    y: '40%'
};
let URL_THEMES = window.location.origin;

//////////////////////// DETECT VIDEO FOR BROWSER ///////////////////
function loadVideoBottle() {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
        bottleVid = $('.product-bottle-inner'),
        ext = isSafari ? 'mov' : 'webm';

    bottleVid.html(`<video muted playsinline loop id="bottleVid">
    <source src="./video/bottle-vid.${ext}">
    </video>`)
    // bottleVid.html(`<video muted playsinline loop id="bottleVid">
    // <source src="${URL_THEMES}/video/bottle-vid.${ext}">
    // </video>`)      
};
loadVideoBottle();

//////////////////////// INIT SCROLL SMOOTH ///////////////////
function scriptHome() {
    if ($('.homepage').length) { 
        locoScroll = new LocomotiveScroll({
            el: document.querySelector('.scrollmain'),
            smooth: true,
            lerp: 0.1,
            getDirection: true,
            reloadOnContextChange: true,
            tablet: { smooth: true },
            smartphone: { smooth: false }, 
            class: 'is-inview',
        });
        locoScroll.on("scroll", ScrollTrigger.update);
    
        ScrollTrigger.scrollerProxy('.scrollmain', {
            scrollTop(value) {
                return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            pinType: document.querySelector('.scrollmain').style.transform ? "transform" : "fixed"
        });
    
        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > header.outerHeight()/2) {
                header.addClass('scroll');
            } else if (instance.scroll.y < header.height()/2) {
                header.removeClass('scroll');
            }
            
            function activeSection() {
                //let intro = document.querySelector('section#intro');
                let productTop = document.querySelector('section#product').offsetTop - 100;
                let testimonialTop = document.querySelector('section#testimonial').offsetTop - 100;
                let blogTop = document.querySelector('section#blog').offsetTop - 100;
                let footerTop = document.querySelector('footer#footer').offsetTop - 100; 
                links.removeClass('active')         
                if (instance.scroll.y < productTop) {
                    $('.link[href="#intro"]').addClass('active');
                } else if (instance.scroll.y < testimonialTop) {
                    $('.link[href="#product"]').addClass('active');
                } else if (instance.scroll.y < blogTop) {
                    $('.link[href="#testimonial"]').addClass('active');
                } else if (instance.scroll.y < footerTop) {
                    $('.link[href="#blog"]').addClass('active');
                } else {
                    $('.link[href="#footer"]').addClass('active');
                }
            };
            activeSection();
            gridBgLimit();
            
        });
    
        ////////////////////////// HOME TEXT ANIMATION //////////////
    
        function animateHome() {    
            const scHero = $('.schero'),
                heroHeading = $('.schero .--h1 .char'),
                heroLogosub = $('.schero .sub-title'),
                heroLogo = $('.schero .logo'),
                heroVideo = $('.scherohome__video');            
            let tlHero = gsap.timeline({
                scrollTrigger: {
                    trigger: scHero,
                    scroller: scrollMain,
                    scrub: false,
                    pin: false,
                    start: 'top top',
                    end: 'bottom',
                }
            });
            if (flagPD == false) {
                gsap.set(heroLogo, { autoAlpha: 1 })
                gsap.set(heroHeading, { autoAlpha: 1 })
                tlHero.from(heroLogo, {y: optAniText.y, opacity: 0, duration: 1, delay: .3})
                .from(heroLogosub, {y: optAniText.y, opacity: 0, duration: optAniText.duration}, "-=.3")
                .from(heroHeading, {y: optAniText.y, opacity: 0, duration: optAniText.duration, stagger: optAniText.stagger}, "-=.6")
                .from(heroVideo, {y: optAniText.y, opacity: 0, duration: 1}, "-=1")            
                tlHero.play();
            };
    
            const scProduct = $('#product'),
                productLogo = $('.scproducthome__logo'),
                productHeading = $('.scproduct .--h2 .char');
            let tlProduct = gsap.timeline({
                scrollTrigger: {
                    trigger: scProduct,
                    scroller: scrollMain,
                    toggleActions: "play none none none",
                    start: "-60% top",
                }
            });
            tlProduct.from(productLogo, {y: optAniText.y, autoAlpha: 0, duration: 1})
            .from(productHeading, {y: optAniText.y, autoAlpha: 0, duration: optAniText.duration, stagger: optAniText.stagger}, "-=.7");
    
            if ($(window).width() >= 991) {
                const productPills = $('#productpills');
                console.log(productPills.css('transform'));
                document.addEventListener('mousemove', function (event) {
                    let mouseX = event.clientX;
                    let mouseY = event.clientY;
        
                    let widthToTranslate = gsap.utils.mapRange(0, window.innerWidth, 10, -10);
        
                    let valueX = widthToTranslate(mouseX);
                    let valueY = widthToTranslate(mouseY);
                    productPills.css('transform','translate(' + valueX + 'px,' + valueY + 'px)');
                });
        
                document.getElementById('feaCta').onmousemove = function clickEvent(e) {
                    // e = Mouse click event.
                    let rect = e.currentTarget.getBoundingClientRect();
                    let x = e.clientX - rect.left; //x position within the element.
                    let y = e.clientY - rect.top;  //y position within the element.
                    let widthToTranslate = gsap.utils.mapRange(0, featureBtnAlias.clientWidth, -20, 20);
              
                    let valueX = widthToTranslate(x);
                    let valueY = widthToTranslate(y);
    
                    if ($(window).width() <= 1279 ) {
                        featureBtnAlias.style.transform = 'translate(calc(100% + (' + valueX + 'px)),' + valueY + 'px)';
                    } else {
                        featureBtnAlias.style.transform = 'translate(' + valueX + 'px,' + valueY + 'px)';
                    }
                
                }
                document.getElementById('feaCta').onmouseleave = function clickEvent(e) {
                    if ($(window).width() <= 1279 ) {
                        featureBtnAlias.style.transform = 'translateX(100%)';
                    } else {
                        featureBtnAlias.style.transform = 'translate(' + 0 + 'px,' + 0 + 'px)';
                    }   
                }
            }
    
            if ($(window).width() >= 991) {
                const productAnim = $('#productanim'),
                    pillL1 = $('#productpills .pills-box').children('.pill').eq(0),
                    pillL2 = $('#productpills .pills-box').children('.pill').eq(1),
                    pillL3 = $('#productpills .pills-box').children('.pill').eq(2),
                    pillL4 = $('#productpills .pills-box').children('.pill').eq(3),
                    pillR1 = $('#productpills .pills-box-right').children('.pill').eq(0),
                    pillR2 = $('#productpills .pills-box-right').children('.pill').eq(1),
                    pillR3 = $('#productpills .pills-box-right').children('.pill').eq(2),
                    pillR4 = $('#productpills .pills-box-right').children('.pill').eq(3),
                    productFeatures = $('#productFea'),
                    featureL1 = $('#productFea .features-box').children('.feature-item').eq(0),
                    featureL2 = $('#productFea .features-box').children('.feature-item').eq(1),
                    featureL3 = $('#productFea .features-box').children('.feature-item').eq(2),
                    featureR1 = $('#productFea .features-box.--right').children('.feature-item').eq(0),
                    featureR2 = $('#productFea .features-box.--right').children('.feature-item').eq(1),
                    featureR3 = $('#productFea .features-box.--right').children('.feature-item').eq(2);                
    
                let tlProductAnim = gsap.timeline({
                    scrollTrigger: {
                        trigger: productAnim,
                        scroller: scrollMain,
                        start: "top top",
                        scrub: 1,
                        pin: true,
                    }
                });
                tlProductAnim
                .to(pillL1, {ease: "power1.inOut", autoAlpha:0, duration:1, y: -280, delay:0.2},"0")
                .to(pillL2, {ease: "power1.inOut", autoAlpha:0, duration:1.2, y: -330, delay:0.3},"0")
                .to(pillL3, {ease: "power1.inOut", autoAlpha:0, duration:1.1, y: -350, delay:0.2},"0")
                .to(pillL4, {ease: "power1.inOut", autoAlpha:0, duration:.9, y: -320},"0")
                .to(pillR1, {ease: "power1.inOut", autoAlpha:0, duration:.9, y: -340, delay:0.5},"0")
                .to(pillR2, {ease: "power1.inOut", autoAlpha:0, duration:1, y: -310, delay:0.3},"0")
                .to(pillR3, {ease: "power1.inOut", autoAlpha:0, duration:1.1, y: -290, delay:0.1},"0")
                .to(pillR4, {ease: "power1.inOut", autoAlpha:0, duration:1.2, y: -340},"0")
                .from(featureL1, { autoAlpha:0 , duration: 1, x: -300 })
                .from(featureR1, { autoAlpha:0 , duration: 1, x: 300 },"-=1")
                .from(featureL2, { autoAlpha:0 , duration: 1, x: -300 },"-=.6")
                .from(featureR2, { autoAlpha:0 , duration: 1, x: 300 },"-=1")
                .from(featureL3, { autoAlpha:0 , duration: 1, x: -300 },"-=.6")
                .from(featureR3, { autoAlpha:0 , duration: 1, x: 300 },"-=1")
            };
    
    
            const scFeature = $('.scfeature'),
                featureHeading = $('.scfeature .--h1 .char');
            let tlFeature = gsap.timeline({
                scrollTrigger: {
                    trigger: scFeature,
                    scroller: scrollMain,
                    toggleActions: "play none none none",
                    start: "-40% top",
                }
            });
            tlFeature.from(featureHeading, {y: optAniText.y, autoAlpha: 0, duration: optAniText.duration, stagger: optAniText.stagger});
    
            const scIntro = $('.scintro'),
                introSubHeading = $('.scintro .--h5 .char'),
                introHeading = $('.scintro .--h4 .char'),
                introBody1 = $('.scintro .content-bodytext').children('.--text').eq(0),
                introBody2 = $('.scintro .content-bodytext').children('.--text').eq(1),
                introLogo = $('.scintro .content-logo');
            let tlIntro = gsap.timeline({
                scrollTrigger: {
                    trigger: scIntro,
                    scroller: scrollMain,
                    toggleActions: "play none none none",
                    start: "-50% top",
                }
            });
            tlIntro.from(introSubHeading, {y: optAniText.y, autoAlpha: 0, duration: optAniText.duration, stagger: optAniText.stagger})
            .from(introHeading, {y: optAniText.y, autoAlpha: 0, duration: optAniText.duration, stagger: optAniText.stagger},"-=.3")
            .from(introBody1, {y: optAniText.y, autoAlpha: 0, duration: 1},"-=.6")
            .from(introBody2, {y: optAniText.y, autoAlpha: 0, duration: 1},"-=.8")
            .from(introLogo, {y: optAniText.y, autoAlpha: 0, duration: .6},"-=.7");
    
            const scTesti = $('.sctestimonial'),
                testiHeading = $('.sctestimonial .--h2 .char');
            let tlTesti = gsap.timeline({
                scrollTrigger: {
                    trigger: scTesti,
                    scroller: scrollMain,
                    toggleActions: "play none none none",
                    start: "-50% top",
                }
            });
            tlTesti.from(testiHeading, {y: optAniText.y, autoAlpha: 0, duration: optAniText.duration, stagger: optAniText.stagger});    
    
    
        };
    
        function animateHero() {
            const loader = document.querySelector('.page-loading')
            const logo = document.querySelectorAll('.page-loading__logo')
            const text = document.querySelectorAll('.page-loading__text.--first')
            const text2 = document.querySelectorAll('.page-loading__text.--second')
            let tlLoadingPage = gsap.timeline({
                scrollTrigger: {
                    trigger: loader,
                    scroller: scrollMain,
                    toggleActions: "play none none pause",
                },
            })
            tlLoadingPage
            .to(logo,{opacity: 1, duration: 1.2})
            .to(logo,{opacity: 1, duration: .6})
            .to(logo,{opacity: 0, duration: 1.2})
            .to(text,{opacity: 1, duration: 1.2})
            .to(text,{opacity: 1, duration: .6})
            .to(text,{opacity: 0, duration: 1.2})
            .to(text2,{opacity: 1, duration: 1.2})
            .to(text2,{opacity: 1, duration: .6})
            .to(text2,{opacity: 0, duration: 1.2})
            .to(loader,{opacity: 0, duration:1.2})
        };
        
        function loadingScreen() {
            const loader = document.querySelector('.page-loading')
            const introVideo = document.querySelector('#introVid')
            const bottleVideo = document.querySelector('#bottleVid')
            gsap.set(loader, {
                opacity: 0,
                visibility: 'hidden',
                delay: 9.2,
                onComplete: () => {
                    loader.classList.add('is-loaded')
                    introVideo.play()
                    bottleVideo.play()                 
                }
            })
        };
        animateHero();
        loadingScreen();
    
        setTimeout(() => {
            animateHome();
            setTimeout(() => {
                locoScroll.update()
                gridBgLimit();
            }, 100);
        }, 8900);
        
        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > nav.outerHeight()/2) {
                nav.addClass('scroll');
            } else if (instance.scroll.y < nav.height()/2) {
                nav.removeClass('scroll');
            }
        });
    
        function scrollTo() {
            if (!locoScroll) return
            $('.link').click(function (e) {
                e.preventDefault()
                let target = $(this).attr('href')
                locoScroll.scrollTo(target)
                links.removeClass('active')
                nav.removeClass('active')
                $(this).addClass('active')
            })
        };
        scrollTo();
    
        function toggles() {
            soundIcon.click(function () {
                soundIcon.toggleClass("is-muted");
                introVid.muted = !introVid.muted;
            })

            navToggle.on("click", function() {
                nav.toggleClass("active");
            });
    
            aboutItem1.on("click", function() {
                popupAll.removeClass('active');
                popup1.addClass('active');
            });
            aboutItem2.on("click", function() {
                popupAll.removeClass('active');
                popup2.addClass('active');
            });
            aboutItem3.on("click", function() {
                popupAll.removeClass('active');
                popup3.addClass('active');
            });
            aboutItem4.on("click", function() {
                popupAll.removeClass('active');
                popup4.addClass('active');
            });
            popupClose.on("click", function() {
                popupAll.removeClass('active');
            });
    
            signupToggle.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleMobile.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleAbout.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            featureBtn.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            closeSignup.on("click", function() {
                popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
            });
            
            signupSubmit.on("click", function() {
                //$(this).preventDefault()
                //popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
                setTimeout(() => {
                    popupSuccess.addClass("active");
                    popupSuccessInner.addClass("active");
                }, 300);
            });
            closeSuccess.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });
            successClose.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });

            moreInfo.on("click", function () {
                popupMoreInfo.addClass("active");
            });
            closeMoreInfo.on("click", function () {
                popupMoreInfo.removeClass("active");
            });
    
        }
        toggles();

        ////////////////////////// SPLITTING TEXT //////////////

        function splittingText() {
            if (hasScrollSmooth == true && flagPD == false) {
                Splitting();
            }
        }
        splittingText();

        //////////////////////// GRID BG LIMIT ////////////////////////

        function gridBgLimit() {
            let gridsWrapper = document.querySelectorAll('.wrapper__background .grid-line');
            let grids = document.querySelectorAll('.wrapper__background .grid-line .grid');
            let scHero = document.querySelector('.scherohome');
            let scAbout = document.querySelector('.scabouthome');
            let scProduct = document.querySelector('.scproducthome');
            let heightLimit = scHero.offsetHeight + scAbout.offsetHeight + scProduct.offsetHeight + "px";
            console.log(heightLimit);
            for (let i = 0; i < grids.length; i++) {
                grids[i].style.height = heightLimit;
            };
        };

        //////////////////////// TESTIMONIAL SWIPER ////////////////////////

        function initSwiper() {
            const swiper = new Swiper('.swiper-container', {
                parallax: true,
                effect: 'fade',
                loop: true,
                fadeEffect: {crossFade: true},
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true,
                },

                speed: 1000,
                pagination: {
                el: '.swiper-pagination',
                clickable: true,
                },

                navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                },
            });
            swiper.init();
        };

        $(window).on("load resize", function(){
            gridBgLimit();
            initSwiper();
        }).resize();

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        ScrollTrigger.refresh();

        if (isMobile()) {
            document.body.classList.add('is-mobile')
        };
    }
};
scriptHome();

function scriptBlogDetail() {
    if ($('.blogdetailpage').length) {
        locoScroll = new LocomotiveScroll({
            el: document.querySelector('.scrollmain'),
            smooth: true,
            lerp: 0.1,
            getDirection: true,
            reloadOnContextChange: true,
            tablet: { smooth: true },
            smartphone: { smooth: false }, 
        });
        locoScroll.on("scroll", ScrollTrigger.update);
    
        ScrollTrigger.scrollerProxy('.scrollmain', {
            scrollTop(value) {
                return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            pinType: document.querySelector('.scrollmain').style.transform ? "transform" : "fixed"
        });

        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > header.outerHeight()/2) {
                header.addClass('scroll');
            } else if (instance.scroll.y < header.height()/2) {
                header.removeClass('scroll');
            }         
        });

        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > nav.outerHeight()/2) {
                nav.addClass('scroll');
            } else if (instance.scroll.y < nav.height()/2) {
                nav.removeClass('scroll');
            }
        });

        function toggles() {
            navToggle.on("click", function() {
                nav.toggleClass("active");
            });

            signupToggle.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleMobile.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleAbout.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            featureBtn.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            closeSignup.on("click", function() {
                popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
            });
            
            signupSubmit.on("click", function() {
                //$(this).preventDefault()
                //popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
                setTimeout(() => {
                    popupSuccess.addClass("active");
                    popupSuccessInner.addClass("active");
                }, 300);
            });
            closeSuccess.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });
            successClose.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });
    
        }
        toggles();

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        ScrollTrigger.refresh();

        if (isMobile()) {
            document.body.classList.add('is-mobile')
        };
    }
};
scriptBlogDetail();

function scriptBlogs() {
    if ($('.blogspage').length) {
        locoScroll = new LocomotiveScroll({
            el: document.querySelector('.scrollmain'),
            smooth: true,
            lerp: 0.1,
            getDirection: true,
            reloadOnContextChange: true,
            tablet: { smooth: true },
            smartphone: { smooth: false }, 
        });
        locoScroll.on("scroll", ScrollTrigger.update);
    
        ScrollTrigger.scrollerProxy('.scrollmain', {
            scrollTop(value) {
                return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            pinType: document.querySelector('.scrollmain').style.transform ? "transform" : "fixed"
        });

        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > header.outerHeight()/2) {
                header.addClass('scroll');
            } else if (instance.scroll.y < header.height()/2) {
                header.removeClass('scroll');
            }         
        });

        locoScroll.on('scroll', (instance) => {
            if (instance.scroll.y > nav.outerHeight()/2) {
                nav.addClass('scroll');
            } else if (instance.scroll.y < nav.height()/2) {
                nav.removeClass('scroll');
            }
        });

        function toggles() {
            navToggle.on("click", function() {
                nav.toggleClass("active");
            });

            signupToggle.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleMobile.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            signupToggleAbout.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            featureBtn.on("click", function() {
                popupSignup.addClass("active");
                popupSignupInner.addClass("active");
            });
            closeSignup.on("click", function() {
                popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
            });
            
            signupSubmit.on("click", function() {
                //$(this).preventDefault()
                //popupSignup.removeClass("active");
                popupSignupInner.removeClass("active");
                setTimeout(() => {
                    popupSuccess.addClass("active");
                    popupSuccessInner.addClass("active");
                }, 300);
            });
            closeSuccess.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });
            successClose.on("click", function() {
                popupSuccess.removeClass("active");
                popupSignupInner.removeClass("active");
                popupSignup.removeClass("active");
                popupSuccessInner.removeClass("active");
            });
    
        }
        toggles();

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        ScrollTrigger.refresh();

        if (isMobile()) {
            document.body.classList.add('is-mobile')
        };
    }
};
scriptBlogs();




